@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;500;600;700&display=swap');
/* We import source code pro */
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;500;600;700&display=swap');

/* We import Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

::selection {
  background-color: #fe9327;
  color: #fff;
}

/* Remove default textarea styles */
textarea {
  appearance: none;
  resize: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
  background: none;
  overflow: auto;
  box-shadow: none;
}
